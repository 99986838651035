<template>
    <div>
        <div class="appHeader bg-primary text-light">
            <div class="left">
            </div>
            <div class="pageTitle">
                PAYGARANTI - {{ $store.getters.siteName }}
            </div>
            <div class="right">
                <a href="app-notifications.html" class="headerButton" data-bs-toggle="modal" data-bs-target="#actionSheetShare">
                    <ion-icon name="share-social-outline" role="img" class="md hydrated" aria-label="share social outline"></ion-icon>
                </a>
            </div>
        </div>

        <div id="appCapsule" v-if="step === 1">

            <form @submit="onSubmit">
                <!-- 1 -->
                <div class="section mt-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic p-0">
                                <div class="exchange-heading">
                                    <label class="group-label" for="fromAmount"></label>
                                    <div class="exchange-wallet-info">
                                    </div>
                                </div>
                                <div class="exchange-group">
                                    <div class="input-col not-empty">
                                        <input type="text" class="form-control form-control-lg pe-0 border-0" id="fromAmount" placeholder="0" required v-model="name" disabled>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="exchange-line">
                        <div class="exchange-icon">
                            <i  role="img" class="md hydrated fas fa-arrow-alt-circle-down" aria-label="swap vertical outline"></i>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic p-0 ">
                                <div class="exchange-heading">
                                    <i class="fas fa-spinner fa-spin fa-3x text-white" v-if="loading"></i>

                                    <b-select :options="banks" v-model="bank_name" class="form-control" v-if="!loading"></b-select>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="exchange-line">
                        <div class="exchange-icon">
                            <i  role="img" class="md hydrated fas fa-arrow-alt-circle-down" aria-label="swap vertical outline"></i>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic p-0">
                                <div class="exchange-heading">
                                    <label class="group-label" for="fromAmount"></label>
                                    <div class="exchange-wallet-info">
                                    </div>
                                </div>
                                <div class="exchange-group">
                                    <div class="input-col not-empty">
                                        <input v-model="amount" type="number" class="form-control form-control-lg pe-0 border-0" id="fromAmount"  placeholder="Yatırım Tutarı Giriniz" min="1" required>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="exchange-line">
                        <div class="exchange-icon">
                            <i  role="img" class="md hydrated fas fa-arrow-alt-circle-down" aria-label="swap vertical outline"></i>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="alert alert-danger">
                        <strong>YATIRIM YAPARKEN AÇIKLAMA KISMINI BOŞ BIRAKINIZ.

                            AÇIKLAMA YAPILAN GÖNDERİMLER İADE EDİLİR

                        </strong>
                    </div>
                    <br>
                    <div class="form-check mb-1">
                        <input v-model="aggred" type="checkbox" class="form-check-input" id="customCheckb1">
                        <label class="form-check-label" for="customCheckb1">Açıklamayı boş bırakmayı kabul ediyorum.</label>
                    </div>

                    <button type="submit" class="btn btn-success btn-block btn-lg" v-if="!loading && aggred">
                        İşleme Başla!
                    </button>
                    <button disabled class="btn btn-success btn-block btn-lg" v-if="!loading && !aggred">
                        İşleme Başla!
                    </button>
                    <button disabled class="btn btn-success btn-block btn-lg" v-if="loading">
                        <i class="fas fa-spinner fa-spin text-white"></i>
                    </button>
                </div>
                <div class=" transparent">
                </div>
                <!-- 1 -->
            </form>
        </div>
        <div id="appCapsule" v-if="step === 2">
            <form @submit="sendPaymentByUser">
                <!-- 2 -->
                <div class="section mt-4 m-2">
                    <div class="form-group basic p-0">
                        <p class="alert alert-primary">7/24 FAST EFT VEYA HAVALE İŞLEMİ İLE YATIRIMI TAMAMLAYABİLİRSİNİZ</p>
                    </div>
                </div>
                <div class="section">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic p-0">
                                <div class="exchange-heading">
                                    <label class="group-label" for="toAmount" @click="copy($store.getters.paymentDetail.account_name)">
                                        {{ $store.getters.paymentDetail.account_name }}
                                        <i class="fas fa-copy"></i>
                                    </label>
                                    <div class="exchange-wallet-info text-white">
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="exchange-line">
                        <div class="exchange-icon">
                            <i  role="img" class="md hydrated fas fa-arrow-alt-circle-down" aria-label="swap vertical outline"></i>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic p-0 ">
                                <div class="exchange-heading">
                                    <label class="group-label" for="toAmount" @click="copy($store.getters.paymentDetail.account_number)">
                                        {{ $store.getters.paymentDetail.account_number }}
                                        <i class="fas fa-copy"></i>
                                    </label>
                                    <div class="exchange-wallet-info text-white">
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="exchange-line">
                        <div class="exchange-icon">
                            <i  role="img" class="md hydrated fas fa-arrow-alt-circle-down" aria-label="swap vertical outline"></i>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic p-0 ">
                                <div class="exchange-heading">
                                    <label class="group-label" for="toAmount">
                                        {{ currency($store.getters.paymentDetail.amount) }}₺
                                    </label>
                                    <div class="exchange-wallet-info text-white">
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="exchange-line">
                        <div class="exchange-icon">
                            <i  role="img" class="md hydrated fas fa-arrow-alt-circle-down" aria-label="swap vertical outline"></i>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="alert alert-warning"><strong> GÖNDERİM SAĞLARKEN AÇIKLAMA KISMINI BOŞ BIRAKIN, AÇIKLAMA YAPILAN GÖNDERİMLER İADE EDİLİR.</strong></div>
                    <br>

                    <button type="submit" class="btn btn-success btn-block btn-lg" v-if="!loading ">
                       <i class="fas fa-check-circle"></i> &nbsp; YATIRIMI TAMAMLADIM
                    </button>
                    <button disabled class="btn btn-success btn-block btn-lg" v-if="loading">
                        <i class="fas fa-spinner fa-spin text-white"></i>
                    </button>
                </div>
                <div class=" transparent">
                </div>
                <!-- 2 -->
            </form>
        </div>

        <!-- App Bottom Menu -->
        <div class="appBottomMenu">
            <a class="item active">
                <div class="col">
                    <i class="fas fa-user text-success"></i>
                    <strong>Bilgileriniz</strong>
                </div>
            </a>
            <a class="item " :class="{'active' : step > 1}">
                <div class="col">
                    <i class="fas fa-university text-white"></i>
                    <strong>Ödeme Bilgileri</strong>
                </div>
            </a>
            <a  class="item">
                <div class="col">
                    <i class="fas fa-check-circle text-white"></i>
                    <strong>İşlem Sonucu</strong>
                </div>
            </a>
        </div>
        <!-- * App Bottom Menu -->
    </div>
</template>

<script>
import apiService from "@/services/apiService";
export default {
    name: "newHome",
    data() {
        return {
            name: this.$store.getters.token.name,
            amount: '',
            loading: false,
            aggred: false,
            tc: '',
            banks: [
                {
                    "value": "Fiba Bank A.S.",
                    "text": "Fiba Bank A.S."
                },
                {
                    "value": "ICBC Turkey Bank",
                    "text": "ICBC Turkey Bank"
                },
                {
                    "value": "Ziraat Bankasi A.S.",
                    "text": "Ziraat Bankasi A.S."
                },
                {
                    "value": "Vakif Katilim Bankasi A.S.",
                    "text": "Vakif Katilim Bankasi A.S."
                },
                {
                    "value": "Türkiye Halk Bankasi A.S.",
                    "text": "Türkiye Halk Bankasi A.S."
                },
                {
                    "value": "HSBC Bank A.S.",
                    "text": "HSBC Bank A.S."
                },
                {
                    "value": "Denizbank A.S.",
                    "text": "Denizbank A.S."
                },
                {
                    "value": "OdeaBank A.S.",
                    "text": "OdeaBank A.S."
                },
                {
                    "value": "Ziraat Katilim Bankasi A.S.",
                    "text": "Ziraat Katilim Bankasi A.S."
                },
                {
                    "value": "Al Baraka Türk Katilim Bankasi A.S.",
                    "text": "Al Baraka Türk Katilim Bankasi A.S."
                },
                {
                    "value": "Kuveyt Türk Katilim Bankasi A.S.",
                    "text": "Kuveyt Türk Katilim Bankasi A.S."
                },
                {
                    "value": "Türkiye Finans Katilim Bankasi A.S.",
                    "text": "Türkiye Finans Katilim Bankasi A.S."
                },
                {
                    "value": "Türkiye Vakiflar Bankasi T.A.O.",
                    "text": "Türkiye Vakiflar Bankasi T.A.O."
                },
                {
                    "value": "Akbank T.A.S.",
                    "text": "Akbank T.A.S."
                },
                {
                    "value": "HSBC Bank A.S.",
                    "text": "HSBC Bank A.S."
                },
                {
                    "value": "Türkiye Is Bankasi A.S.",
                    "text": "Türkiye Is Bankasi A.S."
                },
                {
                    "value": "Yapi ve Kredi Bankasi A.S.",
                    "text": "Yapi ve Kredi Bankasi A.S."
                },
                {
                    "value": "ING BANK",
                    "text": "ING BANK"
                },
                {
                    "value": "QNB Finansbank A.S.",
                    "text": "QNB Finansbank A.S."
                },
                {
                    "value": "Türkiye Garanti Bankasi A.S.",
                    "text": "Türkiye Garanti Bankasi A.S."
                },
                {
                    "value": "Türk Ekonomi Bankasi A.S.",
                    "text": "Türk Ekonomi Bankasi A.S."
                },
                {
                    "value": "Aktif Yatirim Bankasi A.S.",
                    "text": "Aktif Yatirim Bankasi A.S."
                },
                {
                    "value": "Şekerbank T.A.Ş",
                    "text": "Şekerbank T.A.Ş"
                },
                {
                    "value": "Anadolu Bank",
                    "text": "Anadolu Bank"
                },
                {
                    "value": "ENPARA",
                    "text": "ENPARA"
                }
            ],
            banksFromDeposit: [
                {
                    "value": "",
                    "text": "Hangi bankadan yatırım yapacaksınız?"
                },
                {
                    "value": "Akbank T.A.S.",
                    "text": "Akbank T.A.S."
                },
                {
                    "value": "Qnb Finansbank A.S.",
                    "text": "Qnb Finansbank A.S."
                },
                {
                    "value": "Türkiye Garanti Bankasi A.S.",
                    "text": "Türkiye Garanti Bankasi A.S."
                },
                {
                    "value": "Kuveyt Türk Katilim Bankasi A.S.",
                    "text": "Kuveyt Türk Katilim Bankasi A.S."
                },
                {
                    "value": "Vakif Katilim Bankasi A.S.",
                    "text": "Vakif Katilim Bankasi A.S."
                },
                {
                    "value": "Ziraat Katilim Bankasi A.S.",
                    "text": "Ziraat Katilim Bankasi A.S."
                },
                {
                    "value": "Yapi ve Kredi Bankasi A.S.",
                    "text": "Yapi ve Kredi Bankasi A.S."
                },
                {
                    "value": "Fiba Bank A.S.",
                    "text": "Fiba Bank A.S."
                },
                {
                    "value": "ICBC Turkey Bank",
                    "text": "ICBC Turkey Bank"
                },
                {
                    "value": "Türkiye Halk Bankasi A.S.",
                    "text": "Türkiye Halk Bankasi A.S."
                },
                {
                    "value": "HSBC Bank A.S.",
                    "text": "HSBC Bank A.S."
                },
                {
                    "value": "Denizbank A.S.",
                    "text": "Denizbank A.S."
                },
                {
                    "value": "OdeaBank A.S.",
                    "text": "OdeaBank A.S."
                },
                {
                    "value": "Al Baraka Türk Katilim Bankasi A.S.",
                    "text": "Al Baraka Türk Katilim Bankasi A.S."
                },
                {
                    "value": "Türkiye Finans Katilim Bankasi A.S.",
                    "text": "Türkiye Finans Katilim Bankasi A.S."
                },
                {
                    "value": "Türkiye Cumhuriyeti Ziraat Bankasi A.S.",
                    "text": "Türkiye Cumhuriyeti Ziraat Bankasi A.S."
                },
                {
                    "value": "Türkiye Vakiflar Bankasi T.A.O.",
                    "text": "Türkiye Vakiflar Bankasi T.A.O."
                },
                {
                    "value": "HSBC Bank A.S.",
                    "text": "HSBC Bank A.S."
                },
                {
                    "value": "Türkiye Is Bankasi A.S.",
                    "text": "Türkiye Is Bankasi A.S."
                },
                {
                    "value": "ING BANK",
                    "text": "ING BANK"
                },
                {
                    "value": "Türk Ekonomi Bankasi A.S.",
                    "text": "Türk Ekonomi Bankasi A.S."
                },
                {
                    "value": "Aktif Yatirim Bankasi A.S.",
                    "text": "Aktif Yatirim Bankasi A.S."
                },
                {
                    "value": "Şekerbank T.A.Ş",
                    "text": "Şekerbank T.A.Ş"
                },
                {
                    "value": "Anadolu Bank",
                    "text": "Anadolu Bank"
                },
            ],
            bank_name: "FAST",
            deposit_bank_name: 'FAST',
            errorSid: this.$store.getters.errorSid,
            steps: this.$store.getters.steps,
            step: 1,
            trx: this.$route.query.trx,
            depositCheck: this.$store.getters.depositCheck,
            paymentDetail: {},
            api: false,
            copyIt: false,
            panel_two: false,
            redirect: false,
            redirect_url: ''

        }
    },
    props: ['sid', 'token'],
    methods: {
        currency(value){
            return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2})
        },
        checkPayment() {
            this.loading = true
            apiService.checkPayment({sid: this.sid, token: this.token})
                .then((res) => {
                    this.loading = false
                    if(res.state){
                        if(res.data.payment_detail){
                            if(res.data.payment_detail.status === '0'){
                                if(res.data.payment_detail.aninda_deposit){
                                    window.location = res.data.payment_detail.aninda_link
                                }
                                this.$store.dispatch('paymentDetail', res.data.payment_detail)
                                this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
                                this.$store.dispatch('getSteps', {
                                    one: {
                                        error: false,
                                        now: false,
                                        waiting: false,
                                        approved: true
                                    },
                                    two: {
                                        error: false,
                                        now: false,
                                        waiting: false,
                                        approved: true
                                    },
                                    three: {
                                        error: false,
                                        now: true,
                                        waiting: false,
                                        approved: false
                                    },
                                })
                                this.$socket.client.emit('payment', {token: this.token.paymentDetail.token, sid: this.sid})
                            }
                        }
                    }
                })

        },
        onSubmit(event) {
            event.preventDefault()
            this.loading = true
            apiService.getAccount({player_id: this.$route.query.player_id, deposit_bank_name: this.deposit_bank_name, tc: this.tc, bank_name: this.bank_name, sid: this.sid, name: this.name, amount: this.amount, api_trx: this.$route.query.trx})
                .then((res) => {
                    this.$store.dispatch('bankName', this.bank_name)
                    this.loading = false
                    if(res.state) {
                        if(res.approved){
                            this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
                            this.$store.dispatch('getSteps', {
                                one: {
                                    error: false,
                                    now: false,
                                    waiting: false,
                                    approved: true
                                },
                                two: {
                                    error: false,
                                    now: false,
                                    waiting: false,
                                    approved: true
                                },
                                three: {
                                    error: false,
                                    now: false,
                                    waiting: false,
                                    approved: true
                                },
                            })
                            this.$store.dispatch('getToken', {
                                sid: this.sid,
                                name: this.$store.getters.token.name,
                                trx: '',
                                paymentDetail: {
                                    token: '',
                                    payment: false,
                                    status: '',
                                }
                            })
                        }else{
                            this.$store.dispatch('getToken', {
                                sid: this.$store.getters.token.sid,
                                name: this.name,
                                trx: this.$store.getters.token.sid,
                                paymentDetail: {
                                    token: res.token,
                                    payment: true,
                                    status: '0'
                                }
                            })
                            this.$store.dispatch('getSteps', {
                                one: {
                                    error: false,
                                    now: false,
                                    waiting: false,
                                    approved: true
                                },
                                two: {
                                    error: false,
                                    now: false,
                                    waiting: true,
                                    approved: false
                                },
                                three: {
                                    error: false,
                                    now: false,
                                    waiting: true,
                                    approved: false
                                },
                            })
                            this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
                            this.token = res.token
                            this.checkPayment()
                        }
                    }
                    else
                    if(res.payment){
                        this.$store.dispatch('getToken', {
                            sid: this.$store.getters.token.sid,
                            name: this.name,
                            trx: this.$store.getters.token.sid,
                            paymentDetail: {
                                token: res.paymentData.token,
                                payment: true,
                                status: '0',
                                bank_name: res.paymentData.bank_name
                            }
                        })
                        this.$store.dispatch('getSteps', {
                            one: {
                                error: false,
                                now: false,
                                waiting: false,
                                approved: true
                            },
                            two: {
                                error: false,
                                now: false,
                                waiting: false,
                                approved: true
                            },
                            three: {
                                error: false,
                                now: true,
                                waiting: false,
                                approved: false
                            },
                        })
                        this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
                        this.token = res.paymentData.token
                        this.checkPayment()

                    }else{
                        this.$store.dispatch('getConfirmMessage', {show: true, icon: 'error', title: 'Ops!', message: res.message})
                    }
                })
        },
        getBanks(){
            this.loading = true
            apiService.banks()
                .then((res) => {
                    this.loading = false
                    this.banks = res.data
                    setTimeout(() => {
                        this.banks.unshift({value: 'FAST', text: 'FAST Tüm Bankalar (7/24) - 100.000TL MAKS'})
                    }, 200)
                    if(!this.bank_name.length > 0){
                        this.bank_name = null
                    }
                })
        },
        approved(){
            this.$swal({
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'success',
                title: 'Tebrikler!',
                html: '<div class="notif-alert success mtop mbottom"> Yatırımınız başarılı. İlginiz için teşekkürler </div>\n',
            });
            if(this.redirect){
                setTimeout(() => {
                    window.location.href = this.redirect_url;
                }, 2500)
            }
        },
        checking(){
            this.$swal({
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    this.$swal.showLoading()
                },
                icon: 'info',
                title: 'İnceleniyor..',
                html: '<div class="notif-alert info mtop mbottom"> <i class="fas fa-spinner fa-spin spinne"></i> Yatırımınız şuanda inceleniyor kısa süre içerisinde sonuçlandırılacaktır.</div>\n'
            });

        },
        sendPaymentByUser(event){
            event.preventDefault()
            this.loading = true
            apiService.sendPaymentByUser({sid: this.sid, token: this.token.paymentDetail.token})
                .then(() => {
                    this.checking()
                    this.loading = false
                })
        },
        error(){
            this.$swal({
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'error',
                title: 'Hata',
                html: '<div class="notif-alert warning mtop mbottom">'+this.errorSid.message+'</div>\n',
            });
            if(this.redirect){
                setTimeout(() => {
                    window.location.href = this.redirect_url;
                }, 2500)
            }

        },

    },
    created() {
        let middle_name = this.$route.query.middle_name
        if(middle_name.length > 0)
            middle_name = ' '+middle_name+' '
        this.name = this.$route.query.name+middle_name+' '+this.$route.query.surname
        this.getBanks()
    },
    watch: {
        bank_name: function () {
            this.deposit_bank_name = this.bank_name
        },
        steps: function () {
            if(this.steps.three.approved){
                this.approved()
            }
        },
        '$store.getters.errorSid': function() {
            this.errorSid = this.$store.getters.errorSid
        },
        '$store.getters.token': function() {
            this.token = this.$store.getters.token
        },
        '$store.getters.steps': function() {
            this.steps = this.$store.getters.steps
        },
        '$store.getters.depositCheck': function() {
            this.depositCheck = this.$store.getters.depositCheck
            if(this.depositCheck.state && this.depositCheck.token === this.token.paymentDetail.token){
                this.checkPayment()
            }
        },
        errorSid: function () {
            if(!this.errorSid.error && this.errorSid.waiting && !this.steps.three.approved){
                if(this.$store.getters.paymentDetail.sended){
                    this.checking()
                }else{
                    this.step = 2
                }
            }
            if(this.errorSid.error){
                this.error()
            }
        },

    }
}
</script>

<style scoped>

</style>